<template>
  <div class="lContainer -sub -privacyTerms">
    <h1 class="pageTtl text-center">プライバシーポリシー</h1>
    <div class="mContainer -privacyCont">
      <div class="inner">
        <p>
          静岡市中山間地キャンプ場・観光施設予約サイト（以下、「本システム」という。）においては、本システムを安心してご利用いただくため、本システムが提供するサービスの円滑な運営に必要な範囲で、本システムを利用される皆様（以下、「利用者」という。）の個人情報を収集しております。収集した個人情報は、個人情報の利用目的の範囲内で、適切に取り扱われます。
        </p>
        <section class="list">
          <h2 class="ttl">1.個人情報の利用目的</h2>
          <p>
            「取得した個人情報は、以下の事業のみに利用する目的で収集するものであり、それ以外の目的に利用又は提供することは一切ありません。
          </p>
          <div class="box">
            <ol>
              <li>予約管理</li>
              <li>統計管理</li>
              <li>その他本システムの運用に必要な業務</li>
            </ol>
          </div>
        </section>
        <section class="list">
          <h2 class="ttl">2.個人情報の利用の範囲</h2>
          <p>
            利用者の情報は、本システムの運営主体である本市及び施設管理者（指定管理者及び管理受託者。以下、「施設管理者等」という。）以外の者が利用することはありません。また、取得した個人情報は、これら以外の第三者に提供することもありません。ただし、統計的に処理された本システムのアクセス情報、利用者属性等の情報については、公表する場合があります。本市及び施設管理者等は、専ら本システムの運用及び維持管理する目的でのみ利用者の情報を利用します。なお、施設管理者等は、提供された利用者に関する情報について守秘義務を負うとともに、委託された業務以外の目的で情報を使用することはできません。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">3.個人情報の管理</h2>
          <p>
            利用者から提供いただいた個人情報の管理の責任は、本市に帰属し、「静岡市個人情報保護条例」に従い厳重に管理します。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">4.個人情報の安全性確保</h2>
          <p>
            利用者がインターネット経由で本システムにアクセスする場合には、SSL方式（Secure
            Socket
            Layer：インターネット上で情報を暗号化して通信するセキュリティ機能）採用による暗号化通信を行います。これにより、通信データが暗号化され、通信途中で第三者が盗聴、改ざん等することは不可能となります。なお、本システムをご利用になる際には、利用者自らも不正なアクセスからご自身のコンピュータを十分に保護するようにしてください。特に他人と共有しているコンピュータ（インターネットカフェや公共端末など）で本システムをご利用になった時は、サービス終了後には必ず終了し、Webブラウザを閉じるようにしてください。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">5.Cookie（クッキー）について</h2>
          <p>
            本システムでは、利用者が本システムをより便利にご利用いただけるようにCookieとは、Webサーバーから利用者のWebブラウザに対して送付され、利用者のコンピュータ内に記録される英数字から構成される情報のことで、認証機能やユーザー毎に画面をカスタマイズする機能等を実現するために利用しています。Cookieの活用を望まれない場合は、Webブラウザの設定によりCookie受入の拒否や、事前にその旨を表示するよう変更できますが、本市システムの機能の一部がご利用になれない場合があります。
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'Privacy',

  pageOptions: {
    routeConfig: {
      path: '/privacy',
    },
    props: {
      title: 'プライバシーポリシー',
    },
  },
});
</script>
